import React from 'react'
import { Container, Accordion, Card } from 'react-bootstrap'
import '../styles/teachingFaculty.css'
import Layout from "../components/Layout"
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../components/Head';


function TeachingFaculty() {

  const physicsData = [
    {
      sl_no: '01',
      name: 'Mr. Nagaraja M G ',
      qualification: 'M.Sc. B.Ed.',
      designation: 'Physics Lecturer',
      src:"/teaching_faculty/1.jpg"
    },
    {
      sl_no: '02',
      name: 'Ms. Rekha H Gojanur',
      qualification: 'M.Sc. B.Ed.',
      designation: 'Physics Lecturer',
      src:"/teaching_faculty/2.jpg"
    }
    
  ]

  const chemistryData = [
    {
      sl_no: '03',
      name: 'Mr. Chetankumar Patil ',
      qualification: 'M.Sc. B.Ed. KSET',
      designation: 'Chemistry Lecturer',
      src:"/teaching_faculty/3.jpg"
    },
    {
      sl_no: '04',
      name: 'Mr. Vinaykumar S N',
      qualification: 'M.Sc. M.TECH.',
      designation: 'Chemistry Lecturer',
      src:"/teaching_faculty/4.jpg"
    },
    {
        sl_no: '05',
        name: 'Ms. Vijayalaxmi Gorwar',
        qualification: 'M.Sc.(Organic Chemistry)',
        designation: 'Chemistry Lecturer',
        src:"/teaching_faculty/5.jpg"
      },
  ]

  const mathsData = [
    {
      sl_no: '06',
      name: 'Mr. Mahendra G A ',
      qualification: 'M.Sc. B.Ed.',
      designation: 'Mathematics Lecturer',
      src:"/teaching_faculty/6.jpg"
    },
    {
      sl_no: '07',
      name: 'Ms. Vanitha V Gondi ',
      qualification: 'M.Sc. B.Ed.',
      designation: 'Mathematics Lecturer',
      src:"/avatar.png"
    }
  ]
  const biologyData = [
    {
      sl_no: '08',
      name: 'Dr.Satyanarayana R H',
      qualification: 'M.Sc. Ph.D. PDF(China)',
      designation: 'Biology Lecturer',
      src:"/teaching_faculty/8.jpg"
    },
    {
      sl_no: '09',
      name: 'Mr. Shankar B Adrakatti',
      qualification: 'M.Sc. B.Ed.',
      designation: 'Biology Lecturer',
      src:"/teaching_faculty/9.jpg"
    }
  ]

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
    AOS.refresh();

}, []);

  return (
    <div className='overflow-hidden '>
        <Head title="Teaching Faculty"/>
        <Layout>

            <Container className="px-5">
              <div className='teachingFaculty' style={{marginTop:'8rem'}}>
                <header className="sectionHeader halfBorder mb-5">Teaching Faculty</header>

                <div className='' >
                  
                  <Accordion defaultActiveKey="0">

                      <Accordion.Item eventKey="0">
                          <Accordion.Header>Physics Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              {physicsData.map((data,i) => {
                                  return(
                                      <Card className='d-flex justyify-content-center'>
                                          <Card.Img variant="top" src={data.src} />
                                          <Card.Body className='text-center'>
                                              <Card.Title>{data.name}</Card.Title>
                                              <Card.Text className="mb-2">{data.qualification}</Card.Text>
                                              <Card.Subtitle className="mb-2">{data.designation}</Card.Subtitle>
                                          </Card.Body>
                                      </Card>
                                  )
                              })}
                          </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                          <Accordion.Header>Chemistry Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              {chemistryData.map((data,i) => {
                                  return(
                                      <Card className='d-flex justyify-content-center'>
                                          <Card.Img variant="top" src={data.src} />
                                          <Card.Body className='text-center'>
                                              <Card.Title>{data.name}</Card.Title>
                                              <Card.Text className="mb-2">{data.qualification}</Card.Text>
                                              <Card.Subtitle className="mb-2">{data.designation}</Card.Subtitle>
                                          </Card.Body>
                                      </Card>
                                  )
                              })}
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                          <Accordion.Header>Mathematics Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              {mathsData.map((data,i) => {
                                  return(
                                      <Card className='d-flex justyify-content-center'>
                                          <Card.Img variant="top" src={data.src} />
                                          <Card.Body className='text-center'>
                                              <Card.Title>{data.name}</Card.Title>
                                              <Card.Text className="mb-2">{data.qualification}</Card.Text>
                                              <Card.Subtitle className="mb-2">{data.designation}</Card.Subtitle>
                                          </Card.Body>
                                      </Card>
                                  )
                              })}
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                          <Accordion.Header>Biology Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              {biologyData.map((data,i) => {
                                  return(
                                      <Card className='d-flex justyify-content-center'>
                                          <Card.Img variant="top" src={data.src} />
                                          <Card.Body className='text-center'>
                                              <Card.Title>{data.name}</Card.Title>
                                              <Card.Text className="mb-2">{data.qualification}</Card.Text>
                                              <Card.Subtitle className="mb-2">{data.designation}</Card.Subtitle>
                                          </Card.Body>
                                      </Card>
                                  )
                              })}
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                          <Accordion.Header>Computer Science Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              <Card>
                                  <Card.Img variant="top" src="/teaching_faculty/10.jpg" />
                                  <Card.Body className='text-center'>
                                      <Card.Title>Ms. Shwetha D B</Card.Title>
                                      <Card.Text className="mb-2">MCA</Card.Text>
                                      <Card.Subtitle className="mb-2">Computer Science Lecturer</Card.Subtitle>
                                  </Card.Body>
                              </Card>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                          <Accordion.Header>Accountancy and Bussiness Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              <Card>
                                  <Card.Img variant="top" src="/avatar.png" />
                                  <Card.Body className='text-center'>
                                      <Card.Title>Ms. Vidya</Card.Title>
                                      <Card.Text className="mb-2">M.COM B.Ed (Pursuing) </Card.Text>
                                      <Card.Subtitle className="mb-2">Accountancy and Bussiness Lecturer</Card.Subtitle>
                                  </Card.Body>
                              </Card>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                          <Accordion.Header>Statistics Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              <Card>
                                  <Card.Img variant="top" src="/avatar.png" />
                                  <Card.Body className='text-center'>
                                      <Card.Title>Mr. Naveen patil</Card.Title>
                                      <Card.Text className="mb-2 ">M.Com</Card.Text>
                                      <Card.Subtitle className="mb-2 ">Statistics Lecturer</Card.Subtitle>
                                  </Card.Body>
                              </Card>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                          <Accordion.Header>Kannada Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              <Card>
                                  <Card.Img variant="top" src="/teaching_faculty/13.jpg" />
                                  <Card.Body className='text-center'>
                                      <Card.Title>Mr. Ekanatha Joshi</Card.Title>
                                      <Card.Text className="mb-2">M.A. B.Ed.</Card.Text>
                                      <Card.Subtitle className="mb-2">Kannada Lecturer</Card.Subtitle>
                                  </Card.Body>
                              </Card>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="8">
                          <Accordion.Header>Hindi Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              <Card>
                                  <Card.Img variant="top" src="/teaching_faculty/14.jpg" />
                                  <Card.Body className='text-center'>
                                      <Card.Title>Ms. Rajeshwari S Matti</Card.Title>
                                      <Card.Text className="mb-2">B.A. B.Ed (Hindi).</Card.Text>
                                      <Card.Subtitle className="mb-2">Hindi Lecturer</Card.Subtitle>
                                  </Card.Body>
                              </Card>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="9">
                          <Accordion.Header>Sanskrit Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              <Card>
                                  <Card.Img variant="top" src="/teaching_faculty/15.jpg" />
                                  <Card.Body className='text-center'>
                                      <Card.Title>Mr. Dilip S </Card.Title>
                                      <Card.Text className="mb-2">M.A.</Card.Text>
                                      <Card.Subtitle className="mb-2">Sanskrit Lecturer</Card.Subtitle>
                                  </Card.Body>
                              </Card>
                          </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="10">
                          <Accordion.Header>English Staff</Accordion.Header>
                          <Accordion.Body className='d-lg-flex nonTeachingCard '>
                              <Card>
                                  <Card.Img variant="top" src="/teaching_faculty/16.jpg" />
                                  <Card.Body className='text-center'>
                                      <Card.Title>Mr. Kutubgouda Patil </Card.Title>
                                      <Card.Text className="mb-2">M.A. B.Ed.(English)</Card.Text>
                                      <Card.Subtitle className="mb-2">English Lecturer</Card.Subtitle>
                                  </Card.Body>
                              </Card>
                          </Accordion.Body>
                      </Accordion.Item>
                  </Accordion>
                </div>

              </div>
            </Container>
        </Layout>
    </div>
  )
}

export default TeachingFaculty
